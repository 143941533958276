.service-page-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .service-page-list {
    border-radius: 15px;
    width: 100%;
    overflow: hidden;

    .banner {
      width: 60px;
      height: 60px;
    }

    .may-overflow {
      max-height: 180px;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
  }
  .service-page-paging {
    width: 100%;
    padding: 40px 0;
  }

  @media screen and (max-width: 1024px) {
    .service-page-list {
      width: calc(100vw - 30px);
      overflow-x: scroll;

      .list {
        min-width: 864px;
      }
    }
  }
}

.service-modal {
  min-width: 986px;

  form {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .half-size {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    align-items: flex-start;
    justify-content: flex-start;

    .label-input {
      display: flex;
      flex-direction: column;
      &.on-demand {
        flex-direction: row;
        align-items: center;
        label {
          margin-right: 10px;
        }
      }

      label {
        color: var(--primary-text-color);
        font-size: 12px;
        font-family: 'Poppins-Medium';
        padding: 10px 3px;
      }

      input,
      select {
        font-size: 14px;
        font-family: 'Poppins-Regular';
        height: 40px;
        width: 280px;
        border-radius: 5px;
        background-color: var(--background-3);
        border: 1px solid #d9d9d9;
        padding-left: 12px;
        &:focus {
          border-color: #40a9ff;
          outline: none;
        }
      }

      textarea {
        border-radius: 5px;
      }

      .ant-select-selector {
        height: 40px;
        border-radius: 5px;
        width: 280px;
        display: flex;
        align-items: center;
      }

      .service-description {
        position: relative;
        font-size: 14px;
        font-family: 'Poppins-Regular';
        height: 40px;
        width: 480px;
        border-radius: 8px;
        background-color: var(--background-3);
        min-height: 175px;
        overflow: hidden;

        &-input,
        &-layer {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 10;
          border: none;
          overflow-y: scroll;
          padding: 8px;
          border-radius: 8px;
        }

        &-input {
          opacity: 0;
        }

        &-input.focus {
          opacity: 1;
        }

        &-layer.disable {
          background-color: var(--primary-disable-color);
        }

        &-layer.focus {
          display: none;
        }
      }

      .service-type-toggle {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 280px;

        input {
        }
      }
    }

    .price-config {
      width: 100%;

      input {
        width: 280px;
        &:disabled {
          background-color: #f5f5f5;
        }
      }
    }

    .banner-area {
      display: flex;
      flex-direction: row;
      color: var(--primary-text-color);
      width: 100%;

      .banner-item {
        flex: 1;
        display: flex;
        flex-direction: column;

        label {
          font-size: 12px;
          font-family: 'Poppins-Medium';
          padding: 10px 3px;
        }
        span {
          font-size: 12px;
          font-family: 'Poppins-Regular';
          padding: 10px 3px;
          width: 110px;
        }

        .banner-input {
          display: flex;
          flex-direction: row;
          align-items: center;

          .banner-image {
            border-radius: 5px;
            width: 70px;
            height: 70px;
            background-color: var(--background-3);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 70px;
              height: 70px;
            }
          }
        }

        .action {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-left: 8px;

          .upload-banner {
            width: min-content;
            height: min-content;
            padding: 4px;
            background-color: var(--primary-btn-color);
            border-radius: 5px;
            border: none;
            font-size: 11px;
            font-family: 'Poppins-Medium';
            color: var(--primary-btn-text-color);
            cursor: pointer;
            width: 72px;
            text-align: center;
          }

          .remove-banner {
            width: min-content;
            height: min-content;
            padding: 4px;
            background-color: white;
            border-radius: 5px;
            color: var(--primary-btn-color);
            border: 1px solid var(--primary-btn-color);
            font-size: 11px;
            font-family: 'Poppins-Medium';
            cursor: pointer;
            width: 72px;
          }
        }
      }
    }

    .note-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: var(--primary-text-color);

      .note-title {
        font-size: 12px;
        font-family: 'Poppins-Medium';
        padding: 9px 0 0 20px;
      }
      .add-note-button {
        margin-top: 17px;
        width: 110px;
        background: none;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          font-size: 12px;
          font-family: 'Poppins-Medium';
          margin-left: 8px;
        }
      }

      .note-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 20px 12px 20px 44px;
        color: var(--secondary-text-color);
        background-color: var(--background-3);
        border-radius: 5px;
        margin-bottom: 10px;

        .note-item-content {
          flex: 1;
          display: flex;
          flex-direction: column;

          input {
            font-size: 14px;
            font-family: 'Poppins-Regular';
            height: 30px;
            width: 100%;
            border-radius: 5px;
            background-color: var(--background-3);
            border: none;
            padding: 0px 13px;
            box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;

            &:disabled {
              background-color: var(--primary-disable-color);
            }
          }

          .description {
            position: relative;
            height: 118px;
            margin-top: 20px;
            border-radius: 5px;
            background-color: var(--background-3);
            border: none;
            padding: 0px 13px;
            overflow: hidden;

            &-layer,
            &-input {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              z-index: 10;
              border: none;
              padding: 0px 13px;
              box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 7px;
            }

            &-input {
              opacity: 0;
            }

            &-input.focus {
              opacity: 1;
            }

            &-layer.disable {
              background-color: var(--primary-disable-color);
              overflow-y: scroll;
            }

            &-layer.focus {
              display: none;
            }
          }
        }

        .remote-note-button {
          background: none;
          border: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 21px;
          height: 21px;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
    }

    .create-service-btn {
      width: 255px;
      height: 36px;
      margin-top: 30px;
      background-color: var(--primary-btn-color);
      border-radius: 5px;
      border: none;
      font-size: 11px;
      font-family: 'Poppins-Medium';
      color: var(--primary-btn-text-color);
    }

    .edit-service-btn {
      font-size: 11px;
      font-family: 'Poppins-Medium';
      color: var(--primary-text-color);
      padding-right: 30px;
      cursor: pointer;
      border: 1px solid var(--primary-text-color);
      border-radius: 5px;
      width: 255px;
      height: 36px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.service-categories-modal {
  // min-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  .service-categories-list {
    height: 100%;
    display: flex;
    flex-direction: column;

    .list {
      width: 100%;
      flex: 1;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      width: 440px;
      border-radius: 10px;
      overflow: hidden;

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--background-2);
      }

      .row {
        &:nth-child(2n + 1) {
          background-color: var(--background-2);
          .category-icon-image {
            border: 0.5px solid #ffffff;
          }
        }
        &:nth-child(2n) {
          background-color: var(--background-1);

          .category-icon-image {
            border: 0.5px solid #ffffff;
          }
        }

        .action {
          padding-bottom: 5px;
        }

        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .category-icon-image {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          overflow: hidden;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .detail {
    color: var(--primary-text-color);
    margin-top: 12px;

    .add-new-button {
      height: 40px;
      background: none;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      overflow: hidden;
      background-color: var(--background-3);
      padding: 0 25px 25px 25px;
      .label-input {
        display: flex;
        flex-direction: column;
        label {
          color: black;
          font-size: 12px;
          font-family: 'Poppins-SemiBold';
          padding: 10px 3px;
        }

        input,
        select {
          font-size: 14px;
          font-family: 'Poppins-Regular';
          height: 40px;
          border-radius: 5px;
          background-color: var(--input-in-white-bg);
          color: var(--secondary-text-color);
          border: none;
          box-shadow: rgba(78, 78, 78, 0.5) 0px 3px 6px;
        }

        textarea {
          min-height: 175px;
        }
      }

      .category-icon {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        label {
          color: var(--color-1);
          font-size: 14px;
          font-family: 'Poppins-SemiBold';

          padding: 10px 3px;
        }
        span {
          color: var(--color-1);
          font-size: 10px;
          font-family: 'Poppins-Regular';
          padding: 10px 3px;
        }

        .category-icon-input {
          display: flex;
          flex-direction: row;
          align-items: center;

          .category-icon-image {
            border-radius: 5px;
            width: 70px;
            height: 70px;
            background-color: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 70px;
              height: 70px;
            }
          }
          span {
            color: var(--color-1);
            font-size: 12px;
            font-family: 'Poppins-Medium';
            padding: 10px 3px;
            text-decoration: underline;
            margin-left: 20px;
          }
        }
      }

      .on-demand {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        span {
          margin-right: 10px;
          font-family: 'Poppins-SemiBold';
          color: var(--color-1);
          font-size: 14px;
        }
      }

      .create-service-category-btn {
        height: 36px;
        margin-top: 30px;
        background-color: var(--primary-btn-color);
        border-radius: 5px;
        border: none;
        font-size: 11px;
        font-family: 'Poppins-Medium';
        color: var(--primary-btn-text-color);
      }
    }

    .manage-category-header {
      margin-top: 8px;
      font-size: 16px;
      font-family: 'Poppins-SemiBold';
    }
  }
}

.application .site-page {
  .site-page-header .site-page-header-right {
    button.btn-import-csv.ant-btn {
      margin-right: 16px;
      background-color: #fff;
      border: 1px solid var(--background-1);
      span {
        color: var(--background-1);
      }
    }
  }

  .site-page-export {
    margin-bottom: 7px;
  }
}
