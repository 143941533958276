.attach-quote-modal {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  background-color: var(--background-2);
  max-height: 555px;
  width: 1120px;
  font-family: 'Poppins';
  font-size: 12px;
  color: var(--primary-text-color);
  gap: 24px;

  .customer-info {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    width: 30%;
    background-color: var(--quote-background);
    border-radius: 8px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .label-input {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 4px;
        padding-left: 2px;
      }

      input {
        border-radius: 5px;
        padding: 5px 10px;
        height: 45px;
      }
    }
  }

  .quotes {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    background-color: var(--quote-background);
    border-radius: 8px;

    .title {
      font-size: 14px;
      text-decoration: underline;
      font-weight: bold;
    }

    .list {
      max-height: 455px;
      overflow-y: auto;

      .view-button {
        text-decoration: underline;
        cursor: pointer;
      }

      button {
        margin-left: 32px;
        width: 88px;
        height: 25px;
        border-radius: 42px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;

        &.primary {
          background-color: var(--attach-quote-btn-bg);
          border: none;
          color: var(--attach-quote-btn-text-color);

          &:hover:not(:disabled) {
            opacity: 0.9;
          }
        }
      }

      button:disabled {
        background-color: var(--primary-disable-color);
        color: var(--attach-quote-btn-text-color);
        cursor: default;
      }
    }

    .new-quote {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      img {
        cursor: pointer;
        width: 20px;
      }
    }
  }
}

.create-quote-modal {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  max-height: 80%;
  width: 1140px;
  font-family: 'Poppins';
  font-size: 12px;
  color: var(--primary-text-color);
  gap: 24px;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 12px;

    .column {
      flex: 1;
      flex-direction: column;
      max-width: 345px;

      &-2 {
        flex: 2;
      }

      &.fix-content {
        flex: unset;

        .label-input {
          width: fit-content;
        }
      }

      &.flex-width {
        max-width: unset;
      }

      .checkbox {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;

        input[type='checkbox'] {
          margin-right: 8px;
          width: 22px;
          height: 22px;
        }
      }

      .label-input {
        display: flex;
        flex-direction: column;

        .date-time-picker {
          input {
            background-color: var(--background-3);
          }
        }

        :disabled {
          background-color: var(--primary-disable-color);
        }

        label {
          margin-bottom: 4px;
          padding-left: 2px;
        }

        input,
        .ant-select-selector {
          border-radius: 5px;
          padding: 5px 10px;
          height: 45px;
          color: var(--secondary-text-color);

          &.small {
            width: 100px;
          }
        }

        textarea {
          border-radius: 5px;
          padding: 5px 10px;
          height: 100%;
        }

        .link {
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
        }

        .ant-input-number {
          width: 100%;
          border-radius: 5px;
        }
      }

      .add-more-service {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        img {
          cursor: pointer;
        }
      }

      .title {
        margin: 8px 0px;
      }

      .Dropdown-root {
        width: 163px;
      }

      .term {
        background-color: var(--background-3);
        border-radius: 5px;
        min-height: 120px;
        max-height: 480px;
        overflow-y: scroll;
        color: var(--secondary-text-color);
        padding: 12px;
        padding-bottom: 32px;

        div {
          margin-bottom: 8px;
        }
      }

      .wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding-bottom: 32px;

        .total {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          text-align: right;
          width: 310px;

          .price-line {
            padding-left: 50px;
            height: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &.total-price {
              font-size: 18px;
            }

            .value {
              position: relative;
              left: -12px;
            }
          }
        }

        .separator {
          width: 100%;
          border: 1px solid var(--primary-text-color);
          margin: 2px 0px;
        }

        .action-button {
          margin: 16px 0px;
          justify-content: flex-end;
        }
      }

      .quote-discount {
        display: flex;
        gap: 8px;

        .ant-select-selector {
          border: 1px solid #d9d9d9;
          padding: 5px 10px;
          height: 47px;
          width: 75px;
        }

        .ant-input-number {
          width: 75px;
        }
      }

      .quote-service {
        display: flex;

        .ant-select-selector {
          border: 1px solid #d9d9d9;
          padding: 5px 10px;
          height: 47px;
          width: 200px;
          display: flex;
          align-items: center;
        }

        .ant-select-selection-search {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .services {
    width: 100%;
    background-color: var(--background-3);
    color: var(--secondary-text-color);
    border-radius: 5px;
    padding: 8px;
  }

  .download-csv-btn {
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 12px;

    button {
      text-decoration: underline;
      background: inherit;
      border: none;
      outline: none;
      margin-top: 8px;
      padding: 0;
      a {
        color: var(--primary-text-color);
      }
    }
  }

  .quote-add-surcharge {
    margin: 8px 0;

    .ant-input-number {
      border-radius: 8px;
    }

    .ant-input-number-handler-wrap {
      border-radius: 8px;
      left: 0;
    }

    input {
      text-align: right;
    }
  }

  @media screen and(max-width: 1300px) {
    width: 94vw;
  }

  @media screen and(max-width: 912px) {
    .services {
      > .row {
        flex-direction: column;

        .serviceName {
          .flex-width {
            max-width: min-content;
          }
        }
      }
    }
  }
}
